import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0015 = () => (
  <Wrapper>
    <ColumnH1 label="管理監督者とは？正しい定義と管理職との違いを詳しく解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0015.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        社員の労働時間に関する問題は、これまでにもさまざまな形で労使トラブルが相次ぎ、社会問題化し続けて久しい状況にあります。
        <br />
        このような中、法改正により
        <span>労働時間などに関する制限を受けない者</span>
        である<span>「管理監督者」</span>
        に対しても<span>労働時間の把握</span>が義務づけられました。
        <br />
        <br />
        この機会に、そもそも管理監督者とはどのような立場にある者かについて、改めて理解をしていきましょう。今回は、間違えられやすい「管理職」との違いや、管理監督者の詳しい特徴についても、あわせて解説をしていますので、参考にして下さい。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">そもそも「管理監督者」とは</a>
            <ul>
              <li>
                <a href="#toc1-1">
                  ①通常の社員とは異なる職務が与えられている者
                </a>
              </li>
              <li>
                <a href="#toc1-2">
                  ②重要な決裁権や責任、権限が与えられている者
                </a>
              </li>
              <li>
                <a href="#toc1-3">
                  ③通常の社員とは異なる勤務形態をとっている者
                </a>
              </li>
              <li>
                <a href="#toc1-4">
                  ④管理監督者の職務内容に適した待遇が与えられている者
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc2">管理職と管理監督者との違い</a>
          </li>
          <li>
            <a href="#toc3">管理監督者の詳しい特徴</a>
            <ul>
              <li>
                <a href="#toc3-1">①労働時間、休憩、休日</a>
              </li>
              <li>
                <a href="#toc3-2">②有給休暇</a>
              </li>
              <li>
                <a href="#toc3-3">③労働時間の管理</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc4">まとめ</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>そもそも「管理監督者」とは</h1>
      </div>
      <p>
        管理監督者とは、雇用の条件や労務管理の内容が、経営者と一体化した立場で、次の要件すべてに該当する者をいいます。
      </p>
      <div id="toc1-1">
        <h2>①通常の社員とは異なる職務が与えられている者</h2>
      </div>
      <p>
        管理監督者の業務は、法律上で定められている労働時間などを超えて働かざるを得ないものと扱われることから、経営者から指示を受けて働く一般的な社員とは立場が異なるという特徴があります。
      </p>
      <div id="toc1-2">
        <h2>②重要な決裁権や責任、権限が与えられている者</h2>
      </div>
      <p>
        経営者と一体的な立場に置かれている管理監督者は、当然ながら
        <span>経営者より一定の重い責任や権限を付与されている</span>
        ことになります。
        <br />
        これは、たとえば会社内の支店や店舗の責任者を任されている者が、採用や人事査定などに関与する権限を持っている場合などが挙げられます。
        <br />
        <br />
        一方、社内で係長や課長などの役職がついていたり、リーダー的な立場に置かれたりする者であるとしても、業務における決裁権が上司の裁量にゆだねられている場合や、自らの考えで動くことができず、上司からの指示を部下に伝える役割を持つ者については管理監督者と扱われない点に注意が必要です。
      </p>
      <div id="toc1-3">
        <h2>③通常の社員とは異なる勤務形態をとっている者</h2>
      </div>
      <p>
        経営者の立場にある者の場合、会社で定められた所定労働時間外の時間帯に経営に関する判断を行う必要性や、経営に必要な営業活動に出向く必要性が生じるケースがあります。これは管理監督者の場合も同様で、会社経営のために通常の就業時間が当てはまらないような行動をすることが認められていなければなりません。
        <br />
        つまり、一般的な社員と同じく労働時間や休憩、休日を法律に沿って管理をされているような場合は、管理監督者と扱われないことになります。
      </p>
      <div id="toc1-4">
        <h2>④管理監督者の職務内容に適した待遇が与えられている者</h2>
      </div>
      <p>
        管理監督者は、一般的な社員と比べて業務に関するさまざまな責任を負う立場にあります。したがって、基本給額や役職手当などの措置により、その
        <span>職務内容にふさわしい賃金支払などの待遇がなされているか否か</span>
        が管理監督者の判断基準となります。
      </p>
      <Introduction001 />
      <div id="toc2">
        <h1>管理職と管理監督者との違い</h1>
      </div>
      <p>
        ここまでの項目で、管理監督者の定義について説明をしてきましたが、一般的には、部長や課長など一定の役職を与えられた者について「管理職」と呼ぶケースが多いかもしれません。
        <br />
        管理職と管理監督者は、一見同じような立場と思われがちですが、実は異なる点に注意しましょう。
        <br />
        <br />
        管理職とは、
        <span>会社で自身より下の立場にいる者を統括し、管理をしている者</span>
        のことです。管理職の定義づけは会社によって異なり、たとえば係長以上の者を管理職としているケースもあれば、課長以上の者を管理職と扱うケースもあります。
        <br />
        <br />
        一方、管理監督者は、先ほど述べたように
        <span>経営者と同様の立場として職務や権限を与えられている者</span>
        で、通常の社員のように労働時間や休日などの法律における制限がない点に特徴があります。つまり、管理職と管理監督者は同様の立場とはいえず、管理職であっても一般社員と同様に労務管理が必要となるケースがある点に気をつけなければなりません。
      </p>
      <div id="toc3">
        <h1>管理監督者の詳しい特徴</h1>
      </div>
      <p>
        ここからは、管理監督者が一般の労働者とは異なる扱いとなる点、同様の扱いが必要となる点について、詳しく述べていきましょう。
      </p>
      <div id="toc3-1">
        <h2>①労働時間、休憩、休日</h2>
      </div>
      <p>
        まず、労働時間、休憩、休日については、一般労働者に対する規制の対象外となります。そのため、管理監督者は
        <span>36協定の対象外</span>
        となり、1日あたり8時間まで等の労働時間における上限が適用されず、1ヶ月あたり45時間という時間外労働の限度時間も対象にはなりません。
        <br />
        管理監督者には一般労働者とは異なる労務形態を認める必要があることから、たとえば通常ならば承認が必要となる遅刻や早退をする場合でも、報告を行えば認めるなどの柔軟性の高い方法を取るなどの措置が求められます。
        <br />
        <br />
        ただし、深夜残業については、健康管理的な観点から一般労働者と同様の管理が必要になる点について覚えておかなければなりません。
      </p>
      <div id="toc3-2">
        <h2>②有給休暇</h2>
      </div>
      <p>
        有給休暇は、ポジションを問わず働く社員すべてに与えられるべき権利です。したがって、管理監督者に置かれている者についても、一般労働者と同じく
        <span>有給休暇の管理</span>を行い、適切に付与をしなければなりません。
      </p>
      <div id="toc3-3">
        <h2>③労働時間の管理</h2>
      </div>
      <p>
        2019年の法改正により、
        <span>管理監督者の労働時間を管理することが義務化</span>
        されました。これは、国における働き方改革の推進化の流れを受け、一般の社員に対する長時間労働の規制が厳しくなったことで、社員を取りまとめる立場の管理監督者へそのしわよせが来たことも影響しています。管理監督者に負担が集中しないよう、会社に対しては立場を問わずすべての社員に対して正しい勤怠管理を行い、労働時間を把握することが求められているのです。
      </p>
      <div id="toc4">
        <h1 className="read">まとめ</h1>
      </div>
      <p>
        時間外労働という概念のない管理監督者とはいえ、長時間の労働を行えば疲弊し、健康に害を及ぼす可能性があります。また、深夜の残業に対しては一般社員と同様に割増賃金を支払う必要があることも覚えておかなければなりません。
        <br />
        社内全体で皆が安心して働くことのできる環境を作り上げるためにも、
        <span>
          管理監督者についても適切な形で労働時間の把握を行い、管理できるような体制
        </span>
        を早急に整える必要があるでしょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0015
