import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0015 from '../../../components/molecules/columnDetailInner0015'
import UsefulFunctionLayout022 from '../../../components/molecules/usefulFunctionLayout022'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0015 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        '管理監督者とは？管理職との違いや時間外労働など一般社員と異なる注意点',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="管理監督者とは？正しい定義と管理職との違いを詳しく解説"
        description="管理監督者とは、雇用の条件や労務管理の内容が、経営者と一体化した立場です。労働時間や休日などの規制は対象外となりますが、適切な形で労働時間の把握や管理は必要です。"
        ogUrl="https://kintaicloud.jp/column/details/C0015/"
        ogType="article"
        ogTitle="管理監督者とは？正しい定義と管理職との違いを詳しく解説"
        ogDescription="管理監督者とは、雇用の条件や労務管理の内容が、経営者と一体化した立場です。労働時間や休日などの規制は対象外となりますが、適切な形で労働時間の把握や管理は必要です。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0015.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0015 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout022 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0015

export const pageQuery = graphql`
  query C0015 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
